.infinite-scroll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.photos-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.photo-item {
  position: relative;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  overflow: hidden;
}

.photo {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.photo-item.enlarged .photo {
  transform: scale(1.5);
  z-index: 10;
}

.loading-indicator {
  margin-top: 20px;
  font-size: 1rem;
}

@media (max-width: 600px) {
  .photo-item {
    max-width: 200px;
  }

  .loading-indicator {
    font-size: 0.8rem;
  }
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fullscreen-photo {
  max-width: 90%;
  max-height: 90%;
}

