.App {
  text-align: center;
  min-height: 100vh;
  margin: 0;
  color: black;
}

.App-header {
  background-image: url('./PaytonRoper30.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
}

.App-header h1 {
  font-size: 6rem; /* Adjust this value to make the text bigger or smaller */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-image: linear-gradient(to right, black, grey, #add8e6); /* Black, grey, and baby blue gradient */
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Make text color transparent to show the gradient */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-main {
  padding: 20px;
  background-color: #f0f0f0; /* Background color for the rest of the app */
}

.App-footer {
  background-color: #f0f0f0;
  color: #000;
  padding: 10px;
}

